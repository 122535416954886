import React from "react";
import styled from 'styled-components';
import bg from "../images/desert-sunset.jpg";

const ComponentStyles = styled.section`

    background-color: black;
    background: url(${bg}) no-repeat center center; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  border: 10px solid #333333; 
`; 
const SmallerHeading = styled.h1`
font-size: 2rem;
`;

export default function Component4() {
  return (
      <ComponentStyles>
   
        <div className="container">
          <div id="inner">
            <SmallerHeading>01101110 01101111<br/>00100000 01110000 01101001 01101110 01100101 01100001 01110000 01110000 01101100 01100101</SmallerHeading>
          </div>
        </div>
     
    </ComponentStyles>
  );
};
