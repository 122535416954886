import React from "react";
import styled from 'styled-components';
import bg from "../images/prism.jpg";
import vbgwebm from "../images/space-walk.webm";
import vbgmp4 from "../images/space-walk.mp4";

const ComponentStyles = styled.section`
  background-color: black;
  background: url(${bg}) no-repeat center center; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  border: 10px solid #333333;
`; 
const VideoComponentHeading = styled.h1`
  position: fixed;
  text-align: center;
  //top: 50%;        
`;

export default function Component5() {
  return (   
    <>
    <ComponentStyles>
        <VideoComponentHeading>Patroling for hostile aliens</VideoComponentHeading>
        <video
          playsinline
          className="video-player"
          loop
          muted
          autoPlay
        >
          <source src={vbgwebm} type="video/webm" />
          <source src={vbgmp4} type="video/mp4" />
        </video>
    </ComponentStyles>
    </>
  );
};
