import React from "react";
import "normalize.css";
import GlobalStyles from "../styles/GlobalStyles";
import Scroller from "../components/Scroller";
import SEO from "../components/SEO";

const IndexPage = () => {
  return (
    <>
     <SEO />
      <GlobalStyles />
      <Scroller />
    </>
  );
};

export default IndexPage;
