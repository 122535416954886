import React from "react";
import styled from 'styled-components';
import bg from "../images/noun_Megaphone_1563801-purple.svg";

const ComponentStyles = styled.section`

    background-color: black;
    background: url(${bg}) no-repeat center center; 
    background-size: 400px 400px;
   // opacity: 0.65;

    align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  border: 10px solid #333333; 

`;

export default function Component8() {
  return (
      <ComponentStyles>
   
        <div className="container">
          <div id="inner">
          <h1><a href="https://www.instagram.com/explore/tags/pineapplekryptonite/">#PineappleKryptonite</a></h1>
          
          </div>
        </div>
     
    </ComponentStyles>
  );
};
