import React from "react";
import styled from 'styled-components';
import bg from "../images/noun_alien_3437938.svg";

const ComponentStyles = styled.section`

    background-color: black;
    background: url(${bg}) no-repeat center center; 
    background-size: 400px 400px;
   // opacity: 0.65;

    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    border: 10px solid #333333; 

`;

export default function Component9() {
  return (
      <ComponentStyles>
   
        <div className="container">
          <div id="inner">
              <p>
              If your tongue hurts after eating too much pineapple,<br/>you may have extraterrestrial ancestors.
              </p>
            <p><a href="https://www.instagram.com/explore/tags/pineapplekryptonite/">#PineappleKryptonite</a></p>
          
          </div>
        </div>
     
    </ComponentStyles>
  );
};
