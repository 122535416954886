import React from "react";
import styled from 'styled-components';
import bg from "../images/pineapple.jpg";

const ComponentStyles = styled.section`

    background-color: black;
    background: url(${bg}) no-repeat center center; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  border: 10px solid #333333;
`; 

export default function Component3() {
  return (
      <ComponentStyles>
        <div className="container">
          <div id="inner">
            <h1>Is this a Pineapple?</h1>
          </div>
        </div>
    </ComponentStyles>
  );
};
